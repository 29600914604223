import { Link, withAssetPrefix } from "gatsby";
import React from "react";
import {Button, ButtonGroup} from '@material-ui/core';
interface HeaderProps {
  siteTitle : string | any;
  description : string;
}

const Header = (props : HeaderProps) => (
  <header
    style={{
      background: `#3190B7`,
    }}
  >

    <div
      style={{
        margin: `0 auto`,
        padding:"10px",
        maxWidth: 960,
        textAlign:'center',
      }}
    >
      <h2 style={{ margin: 0 }}>
        <Link
          to="/"
          style={{
            color: `white`,
            textDecoration: `none`,
          }}
        >
         🌴 The Vegan Dive 🔥 
        </Link>
      </h2>
     
    </div>
    <div style={{
      margin:`0 auto`,
      maxWidth:960,
      textAlign:'center',
    }}>
      <h4 style={{margin:'0 auto', textAlign:'center', fontWeight:'bold', color:'white'}}>
        All your favorite comfort food, but VEGAN🌱
        Specializing in baked vegan empanadas!🔥
        Check Instagram for <i>weekly specials! 💃</i>
      </h4>
    </div>
    <nav>
      <div style={{margin:'auto', padding:'15px', textAlign:'center', display:'block'}}>
        <Link style={{padding:'25px'}} to="/"><HeaderNavButton text={'Home'}/></Link>
        <Link style={{padding:'25px'}} to="/feed"><HeaderNavButton text={'Feed'}/></Link>
        <Link style={{padding:'25px'}} to="/contact"><HeaderNavButton text={'Contact'}/></Link>
      </div>
    </nav>
  </header>
)
interface HeaderNavButtonProps {
  text : string
}
const HeaderNavButton = (props :HeaderNavButtonProps) => {
  return(<>
    <Button style={{backgroundColor:'#8AC23D', fontWeight:'bolder'}} variant="contained">
      {props.text}
    </Button>
  </>)
}

export default Header;
