/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import Header from "./header";
import "./layout.css";

interface LayoutProps {
  children : React.ReactNode;
}

const Layout = ( props : LayoutProps ) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
          description
        }
      }
    }
  `)

  return (
    <>
      <Header siteTitle={data.site.siteMetadata.title} description={data.site.siteMetadata.description}/>
      <div
        style={{
          margin: `0 auto`,
          maxWidth: 960,
          padding: `0 1.0875rem 1.45rem`,
        }}
      >
        <main>{props.children}</main>
        <footer>
          <div style={{textAlign:'center', margin:'auto'}}>

            © {new Date().getFullYear()}, The Vegan Dive LLC <br></br><br></br>
            {/* <a href="https://www.instagram.com/thevegandive/"><InstagramIcon htmlColor="black" style={{fontSize:'48px'}}/></a> */}
          </div>
        </footer>
      </div>
    </>
  )
}

export default Layout;
